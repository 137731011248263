import { createReducer, on } from '@ngrx/store';
import { ToEntities } from '@teamfoster/sdk/array-utility';
import { ContentPage } from '../../models';
import * as pageActions from '../actions/content-vacancies.action';
import { PageData } from 'src/app/core/models';

export interface ContentLatestVacancyState {
  entities: { [id: number]: ContentPage };
  loaded: boolean;
  loading: boolean;
  error?: any;
}

export const initialState: ContentLatestVacancyState = {
  entities: {},
  // page: { currentPage: 0, pageCount: 0, resultCount: 0, pageSize: 100, filters: {} },
  loaded: false,
  loading: false,
};

export const contentLatestVacancyReducer = createReducer(
  initialState,
  on(pageActions.LoadContentLatestVacancy, (state: ContentLatestVacancyState) => {
    return {
      ...state,
      loading: true,
      loaded: false,
    };
  }),
  on(pageActions.LoadContentLatestVacancySuccess, (state: ContentLatestVacancyState, { content }) => {
    const entities = ToEntities(content.items, 'id', {});

    return {
      ...state,
      entities,
      loaded: true,
      loading: false,
    };
  }),
  on(pageActions.LoadContentLatestVacancyFail, (state: ContentLatestVacancyState, { error }) => {
    return {
      ...state,
      loading: false,
      loaded: false,
      error,
    };
  })
);

export const getContentLatestVacancyEntities = (state: ContentLatestVacancyState) => state.entities;
export const getContentLatestVacancyLoading = (state: ContentLatestVacancyState) => state.loading;
export const getContentLatestVacancyLoaded = (state: ContentLatestVacancyState) => state.loaded;
