@if (team) {
  <a
    [routerLink]="['/team', team.id, team.title | urlRewrite]"
    class="link-unstyled team-card--link"
    [class.set--inactive]="!teamCardClick"
  >
    <article class="team-card card">
      @if (team.image) {
        <!-- <fstr-image
          class="card-img-top"
          [alt]="team.image.alt || team.title"
          [width]="320"
          [height]="300"
          [colSpan]="{ xs: 12, sm: 6 }"
          [image]="team.image"
        ></fstr-image> -->
        <img
          class="card-img-top"
          [src]="team.image.url + '?width=800&height=800&mode=crop&format=jpg&scale=both&quality=70'"
          [alt]="team.image.alt || team.title"
        />
      }
      <main class="card-body">
        <header class="mb-3">
          <h2 class="h6 mb-0">
            <strong>{{ team.title }}</strong>
          </h2>
          @if (team.intro) {
            <em class="mt-2">{{ team.intro }}</em>
          }
        </header>
        <div class="card-text fs-body-xs">{{ team.text | stripHtml | truncate: 200 }}</div>
      </main>
    </article>
  </a>
}
