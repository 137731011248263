import { inject } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import * as actionsPrinciple from '../actions/principle.action';
import { switchMap, map, catchError } from 'rxjs/operators';
import { PrinciplesService } from '../../services/principle.service';

export const LoadSocialChannels$ = createEffect(
  (actions$ = inject(Actions), service = inject(PrinciplesService)) => {
    return actions$.pipe(
      ofType(actionsPrinciple.LoadPrinciples),
      switchMap(() =>
        service.getPrinciples().pipe(
          map(principles => actionsPrinciple.LoadPrincipleSuccess({ principles })),
          catchError(error => of(actionsPrinciple.LoadPrincipleFail({ error })))
        )
      )
    );
  },
  { functional: true }
);
