<a [routerLink]="['/news', news.id, news.title | urlRewrite]" class="link-unstyled">
  <article class="news-card card">
    <figure class="card-img-top">
      @if (news.image && news.image.url) {
        <!-- <fstr-image class="img-fluid" [width]="450" [height]="440" [image]="news.image"></fstr-image> -->
        <img
          class="img-fluid"
          [src]="news.image.url + '?width=800&height=600&anchor=' + news.image.anchor + '&mode=crop&format=jpg&scale=both&quality=70'"
          [alt]="news.image.alt || news.title"
        />
      }
    </figure>
    <main class="card-body px-0 d-flex flex-column">
      <header class="mb-3">
        <h2 class="card-title news-card__title mb-0">
          {{ news.title }}
        </h2>
        @if (showIntro) {
          <div class="card-text mt-3">{{ news.intro ?? news.text | stripHtml | truncate: 200 }}</div>
        }
      </header>
      <footer class="mt-auto mb-0 pt-3">
        <a [routerLink]="['/news', news.id, news.title | urlRewrite]" class="button button--dark" [class.button-icon]="!showIntro">
          @if (showIntro) {
            <span>{{ 'overview-readmore-label' | fromDictionary }}</span>
          }
          <fstr-icon anchor="icon-chevron-right"></fstr-icon>
        </a>
      </footer>
    </main>
  </article>
</a>
