import { createReducer, on } from '@ngrx/store';
import { ToEntities } from '@teamfoster/sdk/array-utility';
import { ContentPage } from '../../models';
import * as pageActions from '../actions/content-news.action';
import { PageData } from 'src/app/core/models';

export interface ContentLatestNewsState {
  entities: { [id: number]: ContentPage };
  loaded: boolean;
  loading: boolean;
  error?: any;
}

export const initialState: ContentLatestNewsState = {
  entities: {},
  // page: { currentPage: 0, pageCount: 0, resultCount: 0, pageSize: 100, filters: {} },
  loaded: false,
  loading: false,
};

export const contentLatestNewsReducer = createReducer(
  initialState,
  on(pageActions.LoadContentLatestNews, (state: ContentLatestNewsState) => {
    return {
      ...state,
      loading: true,
      loaded: false,
    };
  }),
  on(pageActions.LoadContentLatestNewsSuccess, (state: ContentLatestNewsState, { content }) => {
    const entities = ToEntities(content.items, 'id', {});

    return {
      ...state,
      entities,
      loaded: true,
      loading: false,
    };
  }),
  on(pageActions.LoadContentLatestNewsFail, (state: ContentLatestNewsState, { error }) => {
    return {
      ...state,
      loading: false,
      loaded: false,
      error,
    };
  })
);

export const getContentLatestNewsEntities = (state: ContentLatestNewsState) => state.entities;
export const getContentLatestNewsLoading = (state: ContentLatestNewsState) => state.loading;
export const getContentLatestNewsLoaded = (state: ContentLatestNewsState) => state.loaded;
