<a [routerLink]="['/vacancies', content.id, content.title | urlRewrite]" class="link-unstyled">
  <article class="vacancie-card card">
    <div class="row">
      <figure class="col-md-3">
        @if (content.image && content.image.url) {
          <fstr-image
            [alt]="content.image.alt || content.title"
            class="img-fluid"
            [width]="450"
            [height]="440"
            [colSpan]="{ xs: 12, sm: 6 }"
            [image]="content.image"
            [other]="'quality=80'"
          ></fstr-image>
          <!-- <img
            class="img-fluid"
            [src]="content.image.url + '?width=600&height=600&mode=crop&format=webp&scale=both'"
            [alt]="content.image.alt"
          /> -->
        } @else {
          <fstr-image
            [alt]="content.title"
            class="img-fluid"
            [width]="450"
            [height]="440"
            [colSpan]="{ xs: 12, sm: 6 }"
            [image]="{ url: 'vacancy-fallback-card-image' | fromDictionary }"
          ></fstr-image>
        }
      </figure>
      <div class="col-md-9">
        <main class="card-body">
          <header class="mb-3">
            <h2 class="h4 vacancie-card__title text-light mb-0">
              {{ content.title }}
            </h2>
            @if (content.intro) {
              <div class="card-text fs-body-sm mt-3">{{ content.intro | stripHtml | truncate: 300 }}</div>
            }
          </header>
          <footer class="mt-auto mb-0 pt-3">
            <a [routerLink]="['/vacancies', content.id, content.title | urlRewrite]" class="button button-icon button--dark">
              <fstr-icon anchor="icon-chevron-right"></fstr-icon>
            </a>
          </footer>
        </main>
      </div>
    </div>
  </article>
</a>
