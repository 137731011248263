<article class="block-faq section-outset-b">
  <div class="container">
    <h2>{{ block.title }}</h2>

    <main class="">
      @for (item of block.faqs; track item.id) {
        <dl class="list-unstyled card px-3 py-2 bg-white mb-3 accordion" [class.accordion--open]="activeQuestion === item.id">
          <dt class="accordion__title">
            <button
              type="button"
              class="button text-dark fs-body-sm"
              (click)="activeQuestion === item.id ? (activeQuestion = 0) : (activeQuestion = item.id)"
            >
              <fstr-icon anchor="icon-chevron-down"></fstr-icon>
              <span>{{ item.question }}</span>
            </button>
          </dt>
          <dd class="accordion__content">
            <div class="markdown-content pb-3 fs-body-sm" [innerHTML]="item.answer"></div>
          </dd>
        </dl>
      }
    </main>
  </div>
</article>
