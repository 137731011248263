import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromContentVacancies from '../reducers/content-vacancies.reducer';
import { ContentPage } from '../../models';
import * as fromRoot from '../../../store';

export const getContentLatestVacanciesState = createSelector(
  fromFeature.getContentState,
  (state: fromFeature.ContentState) => state.vacanciesPages
);

export const getContentLatestVacanciesEntities = createSelector(
  getContentLatestVacanciesState,
  fromContentVacancies.getContentLatestVacancyEntities
);

export const getAllLatestVacancies = createSelector(getContentLatestVacanciesEntities, entities => {
  return Object.keys(entities).map(id => entities[id as unknown as number]);
});

// export const getAllLatestVacanciesOrdered = createSelector(getAllLatestVacancies, items => {
//   return items.sort((a, b) => (a.publicationDate || 0) - (b.publicationDate || 0));
// });

export const getAllLatestVacanciesOrdered = createSelector(getAllLatestVacancies, (values: ContentPage[]) => {
  return values.sort((a, b) => {
    return new Date(b.publicationDate).getTime() - new Date(a.publicationDate).getTime();
  });
});

export const getContentLatestVacanciesLoaded = createSelector(
  getContentLatestVacanciesState,
  fromContentVacancies.getContentLatestVacancyLoaded
);
export const getContentLatestVacanciesLoading = createSelector(
  getContentLatestVacanciesState,
  fromContentVacancies.getContentLatestVacancyLoading
);
