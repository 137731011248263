<article class="block-navitems section-outset-b">
  <div class="container">
    @if (block.title) {
      <h2>{{ block.title }}</h2>
    }
    <ul class="list-unstyled">
      @for (block of block.navItems; track block.id; let i = $index) {
        <li class="mb-3">
          <a class="button button--dark" [routerLink]="block.url">
            <span class="me-3">{{ block.title }}</span>
            <fstr-icon anchor="icon-chevron-right"></fstr-icon>
          </a>
        </li>
      }
    </ul>
  </div>
</article>
