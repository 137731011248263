import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ContentBlock } from '../../models';
import { CookiePreferences } from '@teamfoster/sdk/cookie-ngrx';

@Component({
  selector: 'app-content-block-faq',
  templateUrl: './content-block-faq.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockFaqComponent {
  @Input({ required: true }) block!: ContentBlock;
  @Input() cookiePreferences!: CookiePreferences;
  @Input() index!: number;

  activeQuestion = 0;
  openAccordion = false;
}
