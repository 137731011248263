import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromContentPages from '../reducers/content-pages.reducer';
import { ContentPage } from '../../models';
import * as fromRoot from '../../../store';

export const getContentPageState = createSelector(fromFeature.getContentState, (state: fromFeature.ContentState) => state.contentPages);

export const getContentPageEntities = createSelector(getContentPageState, fromContentPages.getContentPagesEntities);

export const getAllContentPages = createSelector(getContentPageEntities, entities => {
  return Object.keys(entities).map(id => entities[id as unknown as number]);
});

export const getOrderedPagesByDate = createSelector(getAllContentPages, (values: ContentPage[]) => {
  return values?.sort((a, b) => (new Date(a.publicationDate).getTime() < new Date(b.publicationDate).getTime() ? 1 : -1));
});

export const getLatestTeamPages = createSelector(getAllContentPages, entities => {
  return entities.filter(page => page.contentTypeId === 4);
});

// export const getLatestNewsPages = createSelector(getOrderedPagesByDate, entities => {
//   return entities.filter(page => page.contentTypeId === 2);
// });

export const getContentPagesOverviewLoaded = createSelector(getContentPageState, fromContentPages.getContentPagesOverviewLoaded);
export const getContentPagesOverviewLoading = createSelector(getContentPageState, fromContentPages.getContentPagesOverviewLoading);

export const getContentPagesLoaded = createSelector(getContentPageState, fromContentPages.getContentPagesLoaded);
export const getContentPagesLoading = createSelector(getContentPageState, fromContentPages.getContentPagesLoading);

export const getContentPagesPage = createSelector(getContentPageState, fromContentPages.getContentPagesPage);

export const getSelectedContentPage = createSelector(getContentPageEntities, fromRoot.getRouterState, (entities, router): ContentPage => {
  return router.state && entities[+router.state.params?.['pageId'] || +router.state.data?.['pageId']];
});

export const getSelectedContentPageLoading = createSelector(getContentPagesLoading, fromRoot.getRouterState, (loading, router): boolean => {
  return router.state && loading.includes(+router.state.params?.['pageId'] || +router.state.data?.['pageId']);
});

export const getSelectedContentPageLoaded = createSelector(getContentPagesLoaded, fromRoot.getRouterState, (loaded, router): boolean => {
  return router.state && loaded.includes(+router.state.params?.['pageId'] || +router.state.data?.['pageId']);
});
