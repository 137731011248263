import { ChangeDetectionStrategy, Component, Input, effect, inject, signal } from '@angular/core';
import { ContentBlock } from '../../models';
import { Store } from '@ngrx/store';
import { LoadContentLatestNews, LoadContentOfType } from 'src/app/content/store';
import { ContentPage } from 'src/app/content/models';
import {
  getAllLatestNews,
  getAllLatestNewsOrdered,
  getContentLatestNewsLoaded,
} from 'src/app/content/store/selectors/content-news.selector';

@Component({
  selector: 'app-content-block-news',
  templateUrl: './content-block-news.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockNewsComponent {
  private store = inject(Store);

  @Input() block!: ContentBlock;
  @Input() index!: number;

  news$ = this.store.selectSignal(getAllLatestNewsOrdered);
  newsLoaded$ = this.store.selectSignal(getContentLatestNewsLoaded);
  inview$ = signal(false);

  inview($event: boolean) {
    this.inview$.set($event);
  }

  initNews$ = effect(
    () => {
      const isLoaded = this.newsLoaded$();
      const inview = this.inview$();

      if (!isLoaded && inview) {
        this.store.dispatch(
          LoadContentLatestNews({
            skip: 0,
            take: 5,
          })
        );
      }
    },
    { allowSignalWrites: true }
  );
}
