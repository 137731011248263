import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ContentBlock } from '../../models';
import { trigger } from '@angular/animations';
import { CardAnimation } from 'src/app/animations/card.animation';

@Component({
  selector: 'app-content-block-principle',
  templateUrl: './content-block-principle.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockPrincipleComponent {
  @Input() block!: ContentBlock;
  @Input() index!: number;

  principles = [
    {
      id: 1,
      menuTitle: 'Overview',
      subtitle: '',
      title: 'The starting point',
      image: '//media/uploads/overview.jpeg',
      intro:
        'From the very start, our Thorizon team set out to turn the perceived negatives related to nuclear energy into positives. Using the laws of physics, we designed a reactor technology with unique benefits – a Thorium Molten Salt Reactor (MSR).',
      tooltips: [
        {
          id: 11,
          text: '',
          linkTo: 2,
        },
        {
          id: 12,
          text: '',
          linkTo: 3,
        },
        {
          id: 13,
          text: '',
          linkTo: 3,
        },
        {
          id: 14,
          text: '',
          linkTo: 4,
        },
      ],
    },
    {
      id: 2,
      menuTitle: 'Scale',
      subtitle: '',
      title: 'Scale of the Reactor',
      image: '//media/uploads/scale.jpeg',
      intro:
        'The image on the left gives an indication of the scale of Thorizon’s power plant compared to the 100 meter tall A’dam Tower in Amsterdam.',
      tooltips: [],
    },
    {
      id: 3,
      menuTitle: 'Replaceable core',
      title: 'Modularity',
      image: '//media/uploads/core.jpeg',
      subtitle: 'just like a coffee pod.',
      intro:
        'Our design delivers agility by applying patented modular technology. These are modules that fit into the core of the nuclear reactor and can be taken out and replaced without hassle. The benefit is that these modules remove the material degredation issues that have long been a barrier to implementing molten salt technology.',
      tooltips: [
        {
          id: 31,
          linkTo: null,
          text: '<p>Future core designs can use different amounts of modules. and all modules will fit into the different core designs.</p><p>We basically created the nuclear energy version of coffee pods that fit into a variety of coffee machines.</p>',
        },
      ],
    },
    {
      id: 4,
      menuTitle: 'Waste into energy',
      title: 'Thorium',
      image: '//media/uploads/waste-energy.jpeg',
      subtitle: 'radioactive waste burner.',
      intro:
        'The first part of the name refers to the type of “fuel” used in the reactor. Thorium can be used in combination with existing radioactive waste. This means that we can reduce the amount of radioactive waste currently lying around now and generate clean energy in one go. The reactor thus becomes a waste-burner. Providing the opportunity to create energy out of unwanted material.',
      tooltips: [],
    },
    {
      id: 5,
      menuTitle: 'Molten Salt',
      title: 'Molten Salt',
      subtitle: 'low pressure, high temperature.',
      image: '//media/uploads/molten-salte-2.jpeg',
      intro:
        'The second part of the name refers to the type of “coolant” that is used in the reactor. Molten salt has several benefits. Firstly, since it is already in a molten state, a meltdown situation is effectively impossible. ',
      tooltips: [
        {
          id: 41,
          linkTo: null,
          text: '<p>Secondly, the salt can handle very high temperatures without needing to increase the pressure. This has safety and efficiency benefits. Thirdly, there is no water used in the reactor – this means past accidents resulting in the release of radioactive material are no longer possible.</p>',
        },
      ],
    },
  ];
}
