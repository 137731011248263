import { inject } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import * as actions from '../actions/content-pages.action';
import * as newsActions from '../actions/content-news.action';
import * as vacancyActions from '../actions/content-vacancies.action';
import { switchMap, map, catchError } from 'rxjs/operators';
import { ContentPagesService } from '../../services';
import { Go } from 'src/app/store';

export const LoadPages$ = createEffect(
  (actions$ = inject(Actions), service = inject(ContentPagesService)) => {
    return actions$.pipe(
      ofType(actions.LoadContentPages),
      switchMap(() =>
        service.getPages().pipe(
          map(contentPages => actions.LoadContentPagesSuccess({ contentPages })),
          catchError(error => of(actions.LoadContentPagesFail({ error })))
        )
      )
    );
  },
  { functional: true }
);

export const LoadById$ = createEffect(
  (actions$ = inject(Actions), service = inject(ContentPagesService)) => {
    return actions$.pipe(
      ofType(actions.LoadContentPageById),
      switchMap(({ id }) =>
        service.getPageById(id).pipe(
          map(page => actions.LoadContentPageByIdSuccess({ page })),
          catchError(error => of(actions.LoadContentPageByIdFail({ id, error })))
        )
      )
    );
  },
  { functional: true }
);

export const LoadByIdFail$ = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(actions.LoadContentPageByIdFail),
      map(({ error }) => {
        return Go({ path: ['/' + error?.status ? error.status : '404'], extras: { skipLocationChange: true } });
      })
    );
  },
  { functional: true }
);

export const LoadContentOfType$ = createEffect(
  (actions$ = inject(Actions), service = inject(ContentPagesService)) => {
    return actions$.pipe(
      ofType(actions.LoadContentOfType),
      switchMap(({ skip, take, contentTypeId }) =>
        service.getContent(skip, take, contentTypeId).pipe(
          map(content => actions.LoadContentOfTypeSuccess({ content })),
          catchError(error => of(actions.LoadContentOfTypeFail({ error })))
        )
      )
    );
  },
  { functional: true }
);

export const LoadContentTypeNews$ = createEffect(
  (actions$ = inject(Actions), service = inject(ContentPagesService)) => {
    return actions$.pipe(
      ofType(newsActions.LoadContentLatestNews),
      switchMap(({ skip, take }) =>
        service.getContent(0, take, 2).pipe(
          map(content => newsActions.LoadContentLatestNewsSuccess({ content })),
          catchError(error => of(newsActions.LoadContentLatestNewsFail({ error })))
        )
      )
    );
  },
  { functional: true }
);

export const LoadContentTypeVacancy$ = createEffect(
  (actions$ = inject(Actions), service = inject(ContentPagesService)) => {
    return actions$.pipe(
      ofType(vacancyActions.LoadContentLatestVacancy),
      switchMap(({ skip, take }) =>
        service.getContent(0, take, 5).pipe(
          map(content => vacancyActions.LoadContentLatestVacancySuccess({ content })),
          catchError(error => of(vacancyActions.LoadContentLatestVacancyFail({ error })))
        )
      )
    );
  },
  { functional: true }
);
