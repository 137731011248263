import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconModule } from '@teamfoster/sdk/icon';
import { RouterModule } from '@angular/router';
import { ImageModule } from '@teamfoster/sdk/image';
import { ContentPage } from '../../models';
import { TextUtilityModule } from '@teamfoster/sdk/text-utility';
import { FromDictionaryPipe, LanguageService } from '@teamfoster/sdk/dictionary-ngrx';

@Component({
  selector: 'app-team-card',
  standalone: true,
  imports: [CommonModule, IconModule, RouterModule, ImageModule, TextUtilityModule],
  templateUrl: './team-card.component.html',
  styleUrls: ['./team-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeamCardComponent {
  lang = inject(LanguageService);
  dict = new FromDictionaryPipe(this.lang);

  @Input({ required: true }) team!: ContentPage;

  get teamCardClick() {
    const clickTeamCard = this.dict.transform('click-team-card');
    if (clickTeamCard === 'false') {
      return false;
    }
    return true;
  }
}
