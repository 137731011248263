import { createAction, props } from '@ngrx/store';
import { ContentPage } from '../../models';
import { PagedOverview } from 'src/app/core/models';

// export const LoadContentTeam = createAction('[Team] Load Team Pages');
// export const LoadContentTeamSuccess = createAction('[Team] Load Team Pages Success', props<{ teamPages: ContentPage[] }>());
// export const LoadContentTeamFail = createAction('[Team] Load Team Pages Fail', props<{ error: any }>());

export const LoadContentTeamById = createAction('[Team] Load Team Page by Id', props<{ id: number }>());
export const LoadContentTeamByIdSuccess = createAction('[Team] Load Team Page by Id Success', props<{ page: ContentPage }>());
export const LoadContentTeamByIdFail = createAction('[Team] Load Team Page by Id Fail', props<{ id: number; error: any }>());

export const LoadContentTeams = createAction('[Team] Load Team of type teams', props<{ take?: number; skip?: number }>());
export const LoadContentTeamsSuccess = createAction(
  '[Team] Load Team of type teams Success',
  props<{ content: PagedOverview<ContentPage> }>()
);
export const LoadContentTeamsFail = createAction('[Team] Load Team of type teams Fail', props<{ error: any }>());
