import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ContentBlock } from '../../models';

@Component({
  selector: 'app-content-block-default',
  templateUrl: './content-block-default.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockDefaultComponent {
  @Input() block!: ContentBlock;
  @Input() index!: number;
}
