import { createReducer, on } from '@ngrx/store';

import * as actions from '../actions/principle.action';

import { ToEntities } from '@teamfoster/sdk/array-utility';
import { Principle } from '../../models/principle.model';

export interface PrincipleState {
  entities: { [id: number]: Principle };
  loaded: boolean;
  loading: boolean;
  error?: any;
}

export const initialState: PrincipleState = {
  entities: {},
  loaded: false,
  loading: false,
};

export const principleReducer = createReducer(
  initialState,
  on(actions.LoadPrinciples, (state: PrincipleState) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(actions.LoadPrincipleSuccess, (state: PrincipleState, { principles }) => {
    const entities = ToEntities(principles, 'id', {});
    return {
      ...state,
      entities,
      loading: false,
      loaded: true,
    };
  }),
  on(actions.LoadPrincipleFail, (state: PrincipleState, { error }) => {
    return {
      ...state,
      loading: false,
      loaded: false,
      error,
    };
  })
);

export const getPrincipleEntities = (state: PrincipleState) => state.entities;
export const getPrincipleLoading = (state: PrincipleState) => state.loading;
export const getPrincipleLoaded = (state: PrincipleState) => state.loaded;
