import { Injectable, Optional, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Principle } from '../models/principle.model';

@Injectable({
  providedIn: 'root',
})
export class PrinciplesService {
  constructor(
    private http: HttpClient,
    @Optional()
    @Inject('BASE_URL')
    private BASE_URL: string
  ) {}

  getPrinciples() {
    return this.http.get<Principle[]>(`${this.BASE_URL}api/principles`);
  }
}
