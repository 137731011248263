import { ChangeDetectionStrategy, Component, Input, computed, effect, inject, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconModule } from '@teamfoster/sdk/icon';
import { RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';

import { LoadPrinciples, getOrderedPrinciples, getPrinciplesLoaded } from '../../store';
import { ImageModule } from '@teamfoster/sdk/image';
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';
import { TextUtilityModule } from '@teamfoster/sdk/text-utility';
import { PrincipleTooltip } from '../../models/princpleTooltip.model';
import { Principle } from '../../models/principle.model';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-principles',
  standalone: true,
  imports: [CommonModule, IconModule, RouterModule, ImageModule, DictionaryNgrxModule, TextUtilityModule],
  templateUrl: './principles.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrinciplesComponent {
  private store = inject(Store);

  principleLoaded$ = this.store.selectSignal(getPrinciplesLoaded);
  principles$ = this.store.selectSignal(getOrderedPrinciples);

  activeItemId$ = signal(0);
  fadeIn$ = signal(false);
  activeTip!: PrincipleTooltip;
  showTip = false;

  loaderParamsReady: boolean = false;

  initPrinciples$ = effect(
    () => {
      const isLoaded = this.principleLoaded$();
      const principles = this.principles$();
      const activeId = this.activeItemId$();

      if (!isLoaded) {
        this.store.dispatch(LoadPrinciples());
      }

      if (isLoaded && principles.length && !activeId) {
        this.setActiveItem(this.principles$()[0].id);
        // this.activeItemId$.set(this.principles$()[0].id);
        this.setActiveItem(this.principles$()[0].id);
      }
    },
    { allowSignalWrites: true }
  );

  activeItem$ = computed(() => {
    const items = this.principles$();
    const activeItemId = this.activeItemId$();

    return items.find((item: Principle) => item.id === activeItemId);
  });

  setActiveItem(id: number) {
    const items = this.principles$();
    const existingItem = items.find((item: Principle) => item.id === id);

    if (existingItem) {
      this.activeItemId$.set(id);
      this.fadeIn$.set(false);
    } else {
      console.log('Principle not found');
    }

    setTimeout(() => {
      this.fadeIn$.set(true);
    }, 50);

    this.showTip = false;
  }
}
