<article class="block-team" (fstrInViewport)="inview($event)" [once]="true">
  <div class="block-default__content">
    <header class="mb-5 container">
      @if (block.title) {
        <h2 class="mb-4">{{ block.title }}</h2>
      }

      @if (block.intro) {
        <p class="lead mb-4">{{ block.intro }}</p>
      }
    </header>

    @if (teamsLoaded$().length) {
      <main class="block-team__main mt-4">
        <ul class="list-unstyled block-team__list">
          <swiper-container class="container" init="false" #swiper>
            @for (block of teams$(); track block.id; let i = $index) {
              <swiper-slide>
                <li class="block-team__list__item">
                  <app-team-card [team]="block"></app-team-card>
                </li>
              </swiper-slide>
            }
          </swiper-container>
        </ul>
      </main>
    }

    @if (block.button?.url || block.button?.routerLink) {
      <footer class="mt-5 d-flex gap-3 flex-column container">
        <fstr-link [button]="block.button!" [cssClasses]="'button  button--dark'">
          @if (!block.button?.text) {
            <span>{{ 'lees-meer' | fromDictionary }}</span>
          }
          <fstr-icon anchor="icon-chevron-right"></fstr-icon>
        </fstr-link>

        @if (block.secondaryButton?.url || block.secondaryButton?.routerLink) {
          <fstr-link [button]="block.secondaryButton!" [cssClasses]="' button  button--dark'">
            @if (!block.secondaryButton?.text) {
              <span>{{ 'lees-meer' | fromDictionary }}</span>
            }
            <fstr-icon anchor="icon-chevron-right"></fstr-icon>
          </fstr-link>
        }
      </footer>
    }
  </div>
</article>
