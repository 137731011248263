import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ContentBlock } from '../../models';

@Component({
  selector: 'app-content-block-image',
  templateUrl: './content-block-image.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockImageComponent {
  @Input() block!: ContentBlock;
  @Input() index!: number;

  get imagePullOut() {
    if (this.block.cssClass?.includes('block-image-small')) {
      return '';
    }

    if (this.block.cssClass?.includes('block-image-left')) {
      return 'pull-s';
    }

    if (this.block.cssClass?.includes('block-image-right')) {
      return 'pull-e';
    }

    return '';
  }

  get isImageSmall() {
    return this.block.cssClass?.includes('block-image-small');
  }
}
