import * as fromPages from '../reducers/content-pages.reducer';
import * as fromTeams from '../reducers/content-teams.reducer';
import * as fromNews from '../reducers/content-news.reducer';
import * as fromVacancy from '../reducers/content-vacancies.reducer';

import { ActionReducerMap, createFeatureSelector, MetaReducer } from '@ngrx/store';
import { environment } from '../../../../environments/environment';

export const featureKey = 'content';

export interface ContentState {
  contentPages: fromPages.ContentPagesState;
  teamsPages: fromTeams.ContentTeamsState;
  newsPages: fromNews.ContentLatestNewsState;
  vacanciesPages: fromVacancy.ContentLatestVacancyState;
}

export const reducers: ActionReducerMap<ContentState> = {
  contentPages: fromPages.contentPageReducer,
  teamsPages: fromTeams.contentTeamsReducer,
  newsPages: fromNews.contentLatestNewsReducer,
  vacanciesPages: fromVacancy.contentLatestVacancyReducer,
};

export const getContentState = createFeatureSelector<ContentState>('content');

export const metaReducers: MetaReducer<ContentState>[] = !environment.production ? [] : [];
