<article class="block-image container" [ngClass]="block.cssClass">
  <div class="block-default__content">
    @if (block.image) {
      <figure class="block-image__figure row">
        <div [ngClass]="isImageSmall ? 'col-md-3' : 'col-md-7'">
          <div [ngClass]="imagePullOut">
            <!-- <img [src]="block.image?.url" [alt]="block.image?.alt" class="img-fluid" /> -->
            <fstr-image
              class="img-fluid"
              [alt]="'Afbeelding'"
              [width]="860"
              [height]="550"
              [format]="'jpg'"
              [colSpan]="{ xs: 12, sm: 6 }"
              [image]="block.image"
              [format]="'jpg'"
            ></fstr-image>
          </div>
        </div>
        <div [ngClass]="isImageSmall ? 'col-md-9' : 'col-md-5'">
          @if (block.image?.caption) {
            <figcaption class="block-image__figure__caption">
              @if (block.title) {
                <h2 class="mb-4">{{ block.title }}</h2>
              }
              <div class="markdown-content" [class.text-end]="block.textAlignRight" [innerHTML]="block.image?.caption"></div>
            </figcaption>
          }
        </div>
      </figure>
    }
    @if (block.button?.url || block.button?.routerLink) {
      <footer class="mt-5 d-flex gap-2 flex-column">
        <fstr-link [button]="block.button!" [cssClasses]="'button  button--dark'">
          @if (!block.button?.text) {
            <span>{{ 'lees-meer' | fromDictionary }}</span>
          }
          <fstr-icon anchor="icon-chevron-right"></fstr-icon>
        </fstr-link>

        @if (block.secondaryButton?.url || block.secondaryButton?.routerLink) {
          <fstr-link [button]="block.secondaryButton!" [cssClasses]="'button  button--dark'">
            @if (!block.secondaryButton?.text) {
              <span>{{ 'lees-meer' | fromDictionary }}</span>
            }
            <fstr-icon anchor="icon-chevron-right"></fstr-icon>
          </fstr-link>
        }
      </footer>
    }
  </div>
</article>
