import { createReducer, on } from '@ngrx/store';
import { ToEntities } from '@teamfoster/sdk/array-utility';
import { ContentPage } from '../../models';
import * as pageActions from '../actions/content-teams.action';
import { PageData } from 'src/app/core/models';

export interface ContentTeamsState {
  entities: { [id: number]: ContentPage };
  page: PageData;
  loaded: number[];
  loading: number[];
  overviewLoaded: boolean;
  overviewLoading: boolean;
  error?: any;
}

export const initialState: ContentTeamsState = {
  entities: {},
  page: { currentPage: 0, pageCount: 0, resultCount: 0, pageSize: 100, filters: {} },
  loaded: [],
  loading: [],
  overviewLoaded: false,
  overviewLoading: false,
};

export const contentTeamsReducer = createReducer(
  initialState,
  on(pageActions.LoadContentTeamById, (state: ContentTeamsState, { id }) => {
    return {
      ...state,
      loading: [...state.loading, id],
    };
  }),
  on(pageActions.LoadContentTeamByIdSuccess, (state: ContentTeamsState, { page }) => {
    const entities = { ...state.entities, [page.id]: page };
    return {
      ...state,
      entities,
      loading: state.loading.filter(id => id !== page.id),
      loaded: [...state.loaded, page.id],
    };
  }),
  on(pageActions.LoadContentTeamByIdFail, (state: ContentTeamsState, { error, id }) => {
    return {
      ...state,
      loading: state.loading.filter(id => id !== id),
      loaded: state.loaded.filter(id => id !== id),
      error,
    };
  }),
  on(pageActions.LoadContentTeams, (state: ContentTeamsState) => {
    return {
      ...state,
      overviewLoading: true,
      overviewLoaded: false,
    };
  }),
  on(pageActions.LoadContentTeamsSuccess, (state: ContentTeamsState, { content }) => {
    const entities = ToEntities(content.items, 'id', {});

    return {
      ...state,
      entities,
      page: content.page,
      loaded: [...state.loaded, ...content.items.map(i => i.id)],
      overviewLoading: false,
      overviewLoaded: true,
    };
  }),
  on(pageActions.LoadContentTeamsFail, (state: ContentTeamsState, { error }) => {
    return {
      ...state,
      overviewLoading: false,
      overviewLoaded: false,
      error,
    };
  })
);

export const getContentTeamsEntities = (state: ContentTeamsState) => state.entities;
export const getContentTeamsLoading = (state: ContentTeamsState) => state.loading;
export const getContentTeamsLoaded = (state: ContentTeamsState) => state.loaded;
export const getContentTeamsOverviewLoading = (state: ContentTeamsState) => state.overviewLoading;
export const getContentTeamsOverviewLoaded = (state: ContentTeamsState) => state.overviewLoaded;
export const getContentTeamsPage = (state: ContentTeamsState) => state.page;
