<header
  class="header pt-2 pt-md-4"
  [class.section-outset-b]="image?.url"
  [class.mb-4]="!image?.url"
  (fstrInViewport)="inViewChange($event)"
  [once]="true"
  [threshold]="0.25"
>
  <div class="container" [class.container-size-lg]="!image?.url">
    <div class="row">
      @if ((image && image.url) || videoBg) {
        <figure class="header__crop mb-5 col-md-12">
          @if (image && image.url) {
            <div class="pull-s" appParallax [loadParallax]="inviewPort" [nonce]="false" [parallaxRatio]="-0.1">
              <fstr-image
                class="img-fluid"
                [alt]="image.alt || title"
                [colSpan]="{ sm: 10 }"
                [height]="700"
                [width]="1980"
                [image]="image"
                [priority]="true"
              ></fstr-image>
            </div>
          }
          @if (inviewPort && videoBg) {
            <fstr-html5-video class="page-header__video" [playerVars]="playerVars" [src]="videoBg"></fstr-html5-video>
          }
        </figure>
      }
      <main class="col-md-12 text-light">
        <h1 class="header__title h1" [innerHTML]="title" [class.mb-4]="!intro"></h1>
        @if (subtitle) {
          <h2 class="header__subtitle h4">{{ subtitle }}</h2>
        }

        @if (intro) {
          <p class="header__intro h5 mb-0 mt-md-4">{{ intro }}</p>
        }

        <div class="mt-md-4">
          <ng-content></ng-content>
        </div>
      </main>
    </div>
  </div>
</header>
