import { CUSTOM_ELEMENTS_SCHEMA, ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild, effect, inject } from '@angular/core';
import { ContentBlock } from '../../models';
import { Store } from '@ngrx/store';
import {
  LoadContentOfType,
  LoadContentTeams,
  getAllTeams,
  getAllTeamsOrdered,
  getContentPagesOverviewLoaded,
  getContentTeamsLoaded,
  getLatestTeamPages,
} from 'src/app/content/store';
import { ContentPage } from 'src/app/content/models';
import { SwiperContainer } from 'swiper/element';
import { SwiperOptions } from 'swiper/types';
import { CommonModule } from '@angular/common';
import { IconModule } from '@teamfoster/sdk/icon';
import { RouterModule } from '@angular/router';
import { ImageModule } from '@teamfoster/sdk/image';
import { InViewModule } from '@teamfoster/sdk/in-view';
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';
import { TeamCardComponent } from 'src/app/content/components/team-card/team-card.component';
import { ButtonModule } from '@teamfoster/sdk/button';

@Component({
  selector: 'app-content-block-team',
  standalone: true,
  imports: [CommonModule, IconModule, RouterModule, ImageModule, InViewModule, DictionaryNgrxModule, TeamCardComponent, ButtonModule],
  templateUrl: './content-block-team.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ContentBlockTeamComponent {
  @Input() block!: ContentBlock;
  @Input() index!: number;

  private store = inject(Store);

  teams$ = this.store.selectSignal(getAllTeamsOrdered);
  teamsLoaded$ = this.store.selectSignal(getContentTeamsLoaded);
  swiperLoaded = false;

  @ViewChild('swiper') swiperEl?: ElementRef<SwiperContainer>;

  swiperParams: SwiperOptions = {
    spaceBetween: 40,
    breakpoints: {
      100: {
        slidesPerView: 1,
      },
      640: {
        slidesPerView: 2,
      },
      1024: {
        slidesPerView: 3,
      },
    },
    on: {
      init: () => {
        this.onSwiperInit();
      },
    },
    injectStyles: [
      `
      :host .swiper {
        overflow:inherit;
      }
      `,
    ],
  };

  onSwiperInit() {
    this.swiperLoaded = true;
    // console.log('Swiper initialized');
  }

  loadedContent = effect(() => {
    const loaded = this.teamsLoaded$();

    if (loaded.length) {
      setTimeout(() => {
        this.activateSlider();
      }, 300);
    }
  });

  inview($event: boolean) {
    this.store.dispatch(
      LoadContentTeams({
        skip: 0,
        take: 100,
      })
    );
  }

  activateSlider() {
    if (!this.swiperEl?.nativeElement) {
      return;
    }

    Object.assign(this.swiperEl.nativeElement, this.swiperParams);

    this.swiperEl.nativeElement.initialize();
  }
}
