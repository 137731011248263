import { createAction, props } from '@ngrx/store';
import { ContentPage } from '../../models';
import { PagedOverview } from 'src/app/core/models';

export const LoadContentPages = createAction('[Content] Load Content Pages');
export const LoadContentPagesSuccess = createAction('[Content] Load Content Pages Success', props<{ contentPages: ContentPage[] }>());
export const LoadContentPagesFail = createAction('[Content] Load Content Pages Fail', props<{ error: any }>());

export const LoadContentPageById = createAction('[Content] Load Content Page by Id', props<{ id: number }>());
export const LoadContentPageByIdSuccess = createAction('[Content] Load Content Page by Id Success', props<{ page: ContentPage }>());
export const LoadContentPageByIdFail = createAction('[Content] Load Content Page by Id Fail', props<{ id: number; error: any }>());

export const LoadContentOfType = createAction(
  '[Content] Load Content of type',
  props<{ take?: number; skip?: number; contentTypeId?: number }>()
);

export const LoadContentOfTypeSuccess = createAction(
  '[Content] Load Content of type Success',
  props<{ content: PagedOverview<ContentPage> }>()
);
export const LoadContentOfTypeFail = createAction('[Content] Load Content of type Fail', props<{ error: any }>());
