import { createReducer, on } from '@ngrx/store';
import { ToEntities } from '@teamfoster/sdk/array-utility';
import { ContentPage } from '../../models';
import * as pageActions from '../actions/content-pages.action';
import { PageData } from 'src/app/core/models';

export interface ContentPagesState {
  entities: { [id: number]: ContentPage };
  page: PageData;
  loaded: number[];
  loading: number[];
  overviewLoaded: boolean;
  overviewLoading: boolean;
  error?: any;
}

export const initialState: ContentPagesState = {
  entities: {},
  page: { currentPage: 0, pageCount: 0, resultCount: 0, pageSize: 13, filters: {} },
  loaded: [],
  loading: [],
  overviewLoaded: false,
  overviewLoading: false,
};

export const contentPageReducer = createReducer(
  initialState,
  on(pageActions.LoadContentPages, (state: ContentPagesState) => {
    return {
      ...state,
      overviewLoading: true,
    };
  }),
  on(pageActions.LoadContentPagesSuccess, (state: ContentPagesState, { contentPages }) => {
    const entities = ToEntities(contentPages, 'id', {});

    return {
      ...state,
      entities,
      overviewLoading: false,
      overviewLoaded: true,
    };
  }),
  on(pageActions.LoadContentPagesFail, (state: ContentPagesState, { error }) => {
    return {
      ...state,
      overviewLoading: false,
      overviewLoaded: false,
      error,
    };
  }),
  on(pageActions.LoadContentPageById, (state: ContentPagesState, { id }) => {
    return {
      ...state,
      loading: [...state.loading, id],
    };
  }),
  on(pageActions.LoadContentPageByIdSuccess, (state: ContentPagesState, { page }) => {
    const entities = { ...state.entities, [page.id]: page };
    return {
      ...state,
      entities,
      loading: state.loading.filter(id => id !== page.id),
      loaded: [...state.loaded, page.id],
    };
  }),
  on(pageActions.LoadContentPageByIdFail, (state: ContentPagesState, { error, id }) => {
    return {
      ...state,
      loading: state.loading.filter(id => id !== id),
      loaded: state.loaded.filter(id => id !== id),
      error,
    };
  }),
  on(pageActions.LoadContentOfType, (state: ContentPagesState) => {
    return {
      ...state,
      overviewLoading: true,
      overviewLoaded: false,
    };
  }),
  on(pageActions.LoadContentOfTypeSuccess, (state: ContentPagesState, { content }) => {
    const entities = ToEntities(content.items, 'id', {});

    return {
      ...state,
      entities,
      page: content.page,
      loaded: [],
      overviewLoading: false,
      overviewLoaded: true,
    };
  }),
  on(pageActions.LoadContentOfTypeFail, (state: ContentPagesState, { error }) => {
    return {
      ...state,
      overviewLoading: false,
      overviewLoaded: false,
      error,
    };
  })
);

export const getContentPagesEntities = (state: ContentPagesState) => state.entities;
export const getContentPagesLoading = (state: ContentPagesState) => state.loading;
export const getContentPagesLoaded = (state: ContentPagesState) => state.loaded;
export const getContentPagesOverviewLoading = (state: ContentPagesState) => state.overviewLoading;
export const getContentPagesOverviewLoaded = (state: ContentPagesState) => state.overviewLoaded;
export const getContentPagesPage = (state: ContentPagesState) => state.page;
