<article class="block-partner section-outset-y section-inset-y" (fstrInViewport)="initSwiper()" [once]="true">
  <div class="container position-relative z-2">
    <!--<h2 class="text-max-width-xs" *ngIf="block.title" [innerHTML]="block.title | highlight : '' : false : 'squiggle-round'"></h2>-->

    <h2 class="text-light mb-5">
      {{ block.title }}
    </h2>
  </div>
  @defer {
    <swiper-container #swiper init="false">
      @for (partner of loopingPartnersSlides; track partner.id; let i = $index) {
        <swiper-slide>
          <div>
            <a
              class="partner"
              [href]="partner.link"
              target="_blank"
              [style.--logo-image-url]="'url(' + partner.image.url + '?trim.threshold=80&width=900&height=900&mode=pad)'"
            >
              <img [src]="partner.image.url + '?width=900&format=webp'" [alt]="partner.image" />
            </a>
          </div>
        </swiper-slide>
      }
    </swiper-container>
  }
</article>
