<article class="block-principle section-inset-y px-4 px-md-0">
  <div class="container">
    @if (block.title) {
      <h2 class="mb-5 text-light">{{ block.title }}</h2>
    }

    <main>
      <app-principles></app-principles>
    </main>

    @if (block.button?.url || block.button?.routerLink) {
      <footer class="mt-6">
        <fstr-link [button]="block.button!" [cssClasses]="'button  button--dark'">
          @if (!block.button?.text) {
            <span>{{ 'lees-meer' | fromDictionary }}</span>
          }
          <fstr-icon anchor="icon-chevron-right"></fstr-icon>
        </fstr-link>
      </footer>
    }
  </div>
</article>
