import { ChangeDetectionStrategy, Component, Input, effect, inject, signal } from '@angular/core';
import { ContentBlock } from '../../models';
import { Store } from '@ngrx/store';
import { LoadContentLatestNews, getAllLatestVacanciesOrdered, getContentLatestVacanciesLoaded } from 'src/app/content/store';
import { ContentPage } from 'src/app/content/models';
import { LoadContentLatestVacancy } from 'src/app/content/store/actions/content-vacancies.action';

@Component({
  selector: 'app-content-block-vacanties',
  templateUrl: './content-block-vacanties.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockVacanciesComponent {
  @Input() block!: ContentBlock;
  @Input() index!: number;

  private store = inject(Store);
  vacancies$ = this.store.selectSignal(getAllLatestVacanciesOrdered);
  vacanciesLoaded$ = this.store.selectSignal(getContentLatestVacanciesLoaded);
  inview$ = signal(false);

  inview($event: boolean) {
    this.inview$.set($event);
  }

  initVacancies$ = effect(
    () => {
      const isLoaded = this.vacanciesLoaded$();
      const inview = this.inview$();

      if (!isLoaded && inview) {
        this.store.dispatch(
          LoadContentLatestVacancy({
            skip: 0,
            take: 500,
          })
        );
      }
    },
    { allowSignalWrites: true }
  );
}
