<article class="block-default container">
  <div class="block-default__content">
    <header class="mb-3 mt-md-4">
      @if (block.title) {
        <h2 class="">{{ block.title }}</h2>
      }

      @if (block.subtitle) {
        <h3 class="h4 mt-3 mt-md-4" [ngClass]="block.intro ? 'mb-4' : 'mb-0'">{{ block.subtitle }}</h3>
      }

      @if (block.intro) {
        <p class="lead mt-3 mt-md-4">{{ block.intro }}</p>
      }
    </header>
    <main>
      @if (block.text) {
        <div class="markdown-content" [innerHTML]="block.text | resizeHtmlImages | safe"></div>
      }
    </main>
    @if (block.button?.url || block.button?.routerLink) {
      <footer class="mt-5 d-flex gap-2 flex-column">
        @if (block.openButtonSamePage) {
          <fstr-link [target]="'_self'" [button]="block.button!" [cssClasses]="'button  button--dark'">
            @if (!block.button?.text) {
              <span>{{ 'lees-meer' | fromDictionary }}</span>
            }
            <fstr-icon anchor="icon-chevron-right"></fstr-icon>
          </fstr-link>

          @if (block.secondaryButton?.url || block.secondaryButton?.routerLink) {
            <fstr-link [target]="'_self'" [button]="block.secondaryButton!" [cssClasses]="'button  button--dark'">
              @if (!block.secondaryButton?.text) {
                <span>{{ 'lees-meer' | fromDictionary }}</span>
              }
              <fstr-icon anchor="icon-chevron-right"></fstr-icon>
            </fstr-link>
          }
        } @else {
          <fstr-link [button]="block.button!" [cssClasses]="'button  button--dark'">
            @if (!block.button?.text) {
              <span>{{ 'lees-meer' | fromDictionary }}</span>
            }
            <fstr-icon anchor="icon-chevron-right"></fstr-icon>
          </fstr-link>

          @if (block.secondaryButton?.url || block.secondaryButton?.routerLink) {
            <fstr-link [button]="block.secondaryButton!" [cssClasses]="'button  button--dark'">
              @if (!block.secondaryButton?.text) {
                <span>{{ 'lees-meer' | fromDictionary }}</span>
              }
              <fstr-icon anchor="icon-chevron-right"></fstr-icon>
            </fstr-link>
          }
        }
      </footer>
    }
  </div>
</article>
