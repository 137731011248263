import { inject } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import * as actions from '../actions/content-teams.action';
import { switchMap, map, catchError } from 'rxjs/operators';
import { ContentPagesService } from '../../services';
import { Go } from 'src/app/store';

export const LoadTeams$ = createEffect(
  (actions$ = inject(Actions), service = inject(ContentPagesService)) => {
    return actions$.pipe(
      ofType(actions.LoadContentTeams),
      switchMap(({ skip, take }) =>
        service.getContent(skip, take, 4).pipe(
          //service.getPages().pipe(
          map(content => actions.LoadContentTeamsSuccess({ content })),
          catchError(error => of(actions.LoadContentTeamsFail({ error })))
        )
      )
    );
  },
  { functional: true }
);

export const LoadByTeamId$ = createEffect(
  (actions$ = inject(Actions), service = inject(ContentPagesService)) => {
    return actions$.pipe(
      ofType(actions.LoadContentTeamById),
      switchMap(({ id }) =>
        service.getPageById(id).pipe(
          map(page => actions.LoadContentTeamByIdSuccess({ page })),
          catchError(error => of(actions.LoadContentTeamByIdFail({ id, error })))
        )
      )
    );
  },
  { functional: true }
);

export const LoadTeamIdFail$ = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(actions.LoadContentTeamByIdFail),
      map(({ error }) => {
        return Go({ path: ['/' + error?.status ? error.status : '404'], extras: { skipLocationChange: true } });
      })
    );
  },
  { functional: true }
);
