import { createSelector } from '@ngrx/store';
import { Principle } from '../../models/principle.model';
import * as fromFeature from '../reducers';
import * as fromPrinciple from '../reducers/principle.reducer';

export const getPrinciplesState = createSelector(fromFeature.getPrincipleState, (state: fromFeature.PrincipleState) => state.principles);

export const getPrinciplesEntities = createSelector(getPrinciplesState, fromPrinciple.getPrincipleEntities);
export const getPrinciplesLoaded = createSelector(getPrinciplesState, fromPrinciple.getPrincipleLoaded);
export const getPrinciplesLoading = createSelector(getPrinciplesState, fromPrinciple.getPrincipleLoading);

export const getAllPrinciples = createSelector(getPrinciplesEntities, entities => {
  return Object.keys(entities).map(id => entities[id as unknown as number]);
});

export const getOrderedPrinciples = createSelector(getAllPrinciples, (principles: Principle[]) => {
  return principles.sort((a, b) => a.order - b.order);
});
