import {
  AfterViewInit,
  CUSTOM_ELEMENTS_SCHEMA,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  PLATFORM_ID,
  ViewChild,
  inject,
} from '@angular/core';
import { ContentBlock } from '../../models';
import { CookiePreferences } from '@teamfoster/sdk/cookie-ngrx';

import { register } from 'swiper/element/bundle';
import { SwiperOptions, Swiper } from 'swiper/types';
import { Autoplay, FreeMode } from 'swiper/modules';
import { Partner } from '../../models/partner.model';
import { BehaviorSubject } from 'rxjs';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ButtonModule } from '@teamfoster/sdk/button';
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';
import { IconModule } from '@teamfoster/sdk/icon';
import { ImageModule } from '@teamfoster/sdk/image';
import { InViewModule } from '@teamfoster/sdk/in-view';
import { TeamCardComponent } from 'src/app/content/components/team-card/team-card.component';

@Component({
  selector: 'app-content-block-partner',
  templateUrl: './content-block-partner.component.html',
  standalone: true,
  imports: [CommonModule, IconModule, RouterModule, ImageModule, InViewModule, DictionaryNgrxModule, TeamCardComponent, ButtonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ContentBlockPartnerComponent {
  @Input({ required: true }) block!: ContentBlock;
  @Input() cookiePreferences!: CookiePreferences;
  @Input() index!: number;

  cd = inject(ChangeDetectorRef);
  platformId = inject(PLATFORM_ID);

  @ViewChild('swiper') swiperEl?: ElementRef<any>;

  private _inviewPort = false;

  @Input() set inviewPort(val: boolean) {
    this._inviewPort = val;
    if (val) {
      this.cd.detectChanges();
    }
  }

  params: SwiperOptions = {
    modules: [Autoplay],
    injectStylesUrls: ['swiper/scss/free-mode', 'swiper/scss/autoplay'],
    spaceBetween: 30,
    slidesPerView: 2,
    loop: true,
    freeMode: true,
    autoplay: {
      pauseOnMouseEnter: true,
      stopOnLastSlide: false,
      delay: 0,
      //waitForTransition: false,
    },
    speed: 6000,
    breakpoints: {
      0: {
        slidesPerView: 2.5,
      },
      600: {
        slidesPerView: 3,
      },
      850: {
        slidesPerView: 4,
      },
      1400: {
        slidesPerView: 6,
      },
      3000: {
        slidesPerView: 8,
      },
    },
    injectStyles: [
      `
        :host .swiper-wrapper {
          align-items: center;
        }
        `,
    ],
  };

  initSwiper(): void {
    if (this.swiperEl?.nativeElement) {
      Object.assign(this.swiperEl.nativeElement, this.params);
      this.swiperEl.nativeElement.initialize();
    }
  }

  // preventing loop bug with not enough items..
  get loopingPartnersSlides() {
    if (!this.block.partners?.length) {
      return [];
    }
    return this.repeatToFill(this.block.partners, 16);
  }

  repeatToFill(array: Partner[], targetLength: number): Partner[] {
    const repetitionsNeeded = Math.ceil(targetLength / array.length);
    return Array.from({ length: repetitionsNeeded }, () => array)
      .flat()
      .slice(0, targetLength);
  }
}
