<section class="principle-component">
  @if (principles$(); as principles) {
    <div class="row">
      <nav class="col-md-3 mb-4 mb-md-0 pt-md-6 principle-component__nav">
        @if (activeItem$(); as activeItem) {
          <ul class="list-unstyled">
            @for (principle of principles; track principle.id) {
              <li class="mb-2">
                <button
                  type="button"
                  (click)="setActiveItem(principle.id)"
                  class="button button--dark"
                  [class.bg-dark]="principle.id === activeItem.id"
                >
                  {{ principle.menuTitle }}
                </button>
              </li>
            }
          </ul>
        }
      </nav>
      @if (activeItem$(); as activeItem) {
        <main class="col-md-9 principle-component__main">
          <div class="row principle-component__principle" [class.anim--fadeIn]="fadeIn$()">
            <div class="col-md-7 mb-0 pe-md-3">
              <figure
                class="mb-0 w-100 principle__figure"
                [ngClass]="'principle--' + activeItem.id"
                [class.show--scale-anim]="activeTip?.showScaleAnim"
                [class.tooltip--active]="showTip"
              >
                <!-- @if (fadeIn$()) {
                  <fstr-image
                    [image]="activeItem.image"
                    [alt]="activeItem.image?.alt || activeItem.title || 'Afbeelding'"
                    [format]="'webp'"
                    [width]="1600"
                    [height]="1131"
                    [colSpan]="{ xs: 12 }"
                  ></fstr-image>
                } -->
                <img [src]="activeItem.image.url + '?width=1600&height=1600&format=webp'" [alt]="activeItem.title" />

                <ul class="list-unstyled tooltips">
                  @for (tip of activeItem.tooltips; track tip.id; let i = $index) {
                    <li
                      class="tooltip"
                      [ngClass]="'tip--' + activeItem?.id + tip.id"
                      [class.set--active-item]="activeTip?.id === tip.id && showTip"
                    >
                      @if (tip.text) {
                        <button
                          class="button buton--light button-icon shadow"
                          type="button"
                          [class.set--active]="activeTip?.id === tip.id && showTip"
                          (click)="activeTip = tip; showTip = !showTip"
                        >
                          <fstr-icon anchor="icon-plus"></fstr-icon>
                        </button>
                        <div class="tooltip__content shadow-lg" [class.set--active]="tip.id === activeTip?.id && showTip">
                          <!-- <div class="tooltip__content__inner" [innerHTML]="tip.text"></div> -->
                          @if (tip.title) {
                            <h3 class="h6 mt-0">{{ tip.title }}</h3>
                          }

                          <div class="tooltip__content__inner markdown-content" [innerHTML]="tip.text"></div>
                        </div>
                      } @else if (tip.linkTo) {
                        <button
                          class="button buton--light button-icon shadow"
                          type="button"
                          (click)="setActiveItem(tip.linkTo); showTip = false"
                        >
                          <fstr-icon anchor="icon-chevron-right"></fstr-icon>
                        </button>
                      }
                    </li>
                  }
                </ul>
              </figure>
            </div>
            <main class="col-md-5 pt-md-6">
              <div class="ps-md-4 principle__content">
                <header class="mb-4">
                  <h2 class="h3 mb-0">{{ activeItem.title }}</h2>
                  @if (activeItem.subtitle) {
                    <h3 class="h4 mb-0">{{ activeItem.subtitle }}</h3>
                  }
                </header>
                <div class="markdown-content" [innerHTML]="activeItem.intro"></div>
              </div>
            </main>
          </div>
        </main>
      }
    </div>
  }
</section>
