import * as from from './principle.reducer';
import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

export const featureKey = 'principles';

export interface PrincipleState {
  principles: from.PrincipleState;
}

export const reducers: ActionReducerMap<PrincipleState> = {
  principles: from.principleReducer,
};

export const getPrincipleState = createFeatureSelector<PrincipleState>(featureKey);
