import { createAction, props } from '@ngrx/store';
import { ContentPage } from '../../models';
import { PagedOverview } from 'src/app/core/models';

// export const LoadContentTeam = createAction('[Team] Load Team Pages');
// export const LoadContentTeamSuccess = createAction('[Team] Load Team Pages Success', props<{ teamPages: ContentPage[] }>());
// export const LoadContentTeamFail = createAction('[Team] Load Team Pages Fail', props<{ error: any }>());

export const LoadContentNewsById = createAction('[News] Load News Page by Id', props<{ id: number }>());
export const LoadContentNewsByIdSuccess = createAction('[News] Load News Page by Id Success', props<{ page: ContentPage }>());
export const LoadContentNewsByIdFail = createAction('[News] Load News Page by Id Fail', props<{ id: number; error: any }>());

export const LoadContentLatestNews = createAction('[News] Load News of type News', props<{ take?: number; skip?: number }>());
export const LoadContentLatestNewsSuccess = createAction(
  '[News] Load News of type News Success',
  props<{ content: PagedOverview<ContentPage> }>()
);
export const LoadContentLatestNewsFail = createAction('[News] Load News of type News Fail', props<{ error: any }>());
