import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromContentTeams from '../reducers/content-teams.reducer';
import { ContentPage } from '../../models';
import * as fromRoot from '../../../store';

export const getContentTeamsState = createSelector(fromFeature.getContentState, (state: fromFeature.ContentState) => state.teamsPages);

export const getContentTeamsEntities = createSelector(getContentTeamsState, fromContentTeams.getContentTeamsEntities);

export const getAllTeams = createSelector(getContentTeamsEntities, entities => {
  return Object.keys(entities).map(id => entities[id as unknown as number]);
});

export const getAllTeamsOrdered = createSelector(getAllTeams, items => {
  return items.sort((a, b) => (a.order || 0) - (b.order || 0));
});

export const getContentTeamsOverviewLoaded = createSelector(getContentTeamsState, fromContentTeams.getContentTeamsOverviewLoaded);
export const getContentTeamsOverviewLoading = createSelector(getContentTeamsState, fromContentTeams.getContentTeamsOverviewLoading);

export const getContentTeamsLoaded = createSelector(getContentTeamsState, fromContentTeams.getContentTeamsLoaded);
export const getContentTeamsLoading = createSelector(getContentTeamsState, fromContentTeams.getContentTeamsLoading);

export const getContentTeamsPage = createSelector(getContentTeamsState, fromContentTeams.getContentTeamsPage);

export const getSelectedTeamPage = createSelector(getContentTeamsEntities, fromRoot.getRouterState, (entities, router): ContentPage => {
  return router.state && entities[+router.state.params?.['pageId'] || +router.state.data?.['pageId']];
});

export const getSelectedTeamPageLoading = createSelector(getContentTeamsLoading, fromRoot.getRouterState, (loading, router): boolean => {
  return router.state && loading.includes(+router.state.params?.['pageId'] || +router.state.data?.['pageId']);
});

export const getSelectedTeamPageLoaded = createSelector(getContentTeamsLoaded, fromRoot.getRouterState, (loaded, router): boolean => {
  return router.state && loaded.includes(+router.state.params?.['pageId'] || +router.state.data?.['pageId']);
});
