<article class="block-news container" (fstrInViewport)="inview($event)" [once]="true">
  <div class="block-default__content">
    <header class="mb-5">
      @if (block.title) {
        <h2 class="mb-4">{{ block.title }}</h2>
      }

      @if (block.intro) {
        <p class="lead mb-4">{{ block.intro }}</p>
      }
    </header>

    <main>
      @if (newsLoaded$()) {
        <ul class="list-unstyled block-news__list">
          @for (block of news$() | slice: 0 : 5; track block.id; let i = $index) {
            <li class="block-news__list__item">
              <app-news-card [news]="block"></app-news-card>
            </li>
          }
        </ul>
      }
    </main>

    @if (block.button?.url || block.button?.routerLink) {
      <footer class="mt-5 d-flex gap-3 flex-column">
        <fstr-link [button]="block.button!" [cssClasses]="'button  button--dark'">
          @if (!block.button?.text) {
            <span>{{ 'lees-meer' | fromDictionary }}</span>
          }
          <fstr-icon anchor="icon-chevron-right"></fstr-icon>
        </fstr-link>

        @if (block.secondaryButton?.url || block.secondaryButton?.routerLink) {
          <fstr-link [button]="block.secondaryButton!" [cssClasses]="' button  button--dark'">
            @if (!block.secondaryButton?.text) {
              <span>{{ 'lees-meer' | fromDictionary }}</span>
            }
            <fstr-icon anchor="icon-chevron-right"></fstr-icon>
          </fstr-link>
        }
      </footer>
    }
  </div>
</article>
