import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TextUtilityModule } from '@teamfoster/sdk/text-utility';
import { CoreModule } from '../core/core.module';
import { CookieNgrxModule } from '@teamfoster/sdk/cookie-ngrx';
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';
import { ContentBlockContainerComponent } from './containers/content-block-container/content-block-container/content-block-container.component';
import { ContentBlockDefaultComponent } from './components/content-block-default/content-block-default.component';
import { ContentBlockPartnerComponent } from './components/content-block-partner/content-block-partner.component';
import { ContentBlockNavItemComponent } from './components/content-block-navItem/content-block-navItem.component';

import { ImageModule } from '@teamfoster/sdk/image';
import { ButtonModule } from '@teamfoster/sdk/button';
import { IconModule } from '@teamfoster/sdk/icon';
import { ContentBlockPrincipleComponent } from './components/content-block-principle/content-block-principle.component';
import { ContentBlockTeamComponent } from './components/content-block-team/content-block-team.component';
import { ContentBlockImageComponent } from './components/content-block-image/content-block-image.component';
import { ContentBlockNewsComponent } from './components/content-block-news/content-block-news.component';
import { InViewModule } from '@teamfoster/sdk/in-view';
import { TeamCardComponent } from '../content/components/team-card/team-card.component';
import { register } from 'swiper/element/bundle';
import { NewsCardComponent } from '../content/components/news-card/news-card.component';

import { ContentBlockVacanciesComponent } from './components/content-block-vacancies/content-block-vacanties.component';
import { ContentBlockFaqComponent } from './components/content-block-faq/content-block-faq.component';
import { OverviewCardComponent } from '../content/components/oveview-card/overview-card.component';
import { VacancyCardComponent } from '../content/components/vacancie-card/vacancy-card.component';
import { PrinciplesComponent } from '../principle/components/principles/principles.component';

export const moduleExports = [
  ContentBlockContainerComponent,
  ContentBlockPrincipleComponent,
  ContentBlockImageComponent,
  ContentBlockDefaultComponent,
  ContentBlockNavItemComponent,
  ContentBlockNewsComponent,
  ContentBlockFaqComponent,
  ContentBlockVacanciesComponent,
];

register();
@NgModule({
  declarations: [...moduleExports],
  imports: [
    TeamCardComponent,
    CommonModule,
    RouterModule,
    NewsCardComponent,
    TextUtilityModule,
    CoreModule,
    ContentBlockTeamComponent,
    ContentBlockPartnerComponent,
    CookieNgrxModule,
    DictionaryNgrxModule,
    ImageModule,
    ButtonModule,
    IconModule,
    InViewModule,
    OverviewCardComponent,
    VacancyCardComponent,
    PrinciplesComponent,
  ],
  exports: [...moduleExports],
})
export class ContentBlocksModule {}
