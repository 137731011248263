<article class="block-vacancies container" (fstrInViewport)="inview($event)" [once]="true">
  <div class="block-default__content">
    <header class="mb-5">
      @if (block.title) {
        <h2 class="">{{ block.title }}</h2>
      }

      @if (block.intro) {
        <p class="fs-body-sm mt-2 mb-4">{{ block.intro }}</p>
      }
    </header>

    <main>
      <ul class="list-unstyled block-vacancies__list">
        @for (block of vacancies$(); track block.id; let i = $index) {
          <li class="block-vacancies__list__item mb-3">
            <app-vacancy-card [content]="block"></app-vacancy-card>
          </li>
        }
      </ul>
    </main>

    @if (block.button?.url || block.button?.routerLink) {
      <footer class="mt-5 d-flex gap-3 flex-column">
        <fstr-link [button]="block.button!" [cssClasses]="'button  button--dark'">
          @if (!block.button?.text) {
            <span>{{ 'lees-meer' | fromDictionary }}</span>
          }
          <fstr-icon anchor="icon-chevron-right"></fstr-icon>
        </fstr-link>

        @if (block.secondaryButton?.url || block.secondaryButton?.routerLink) {
          <fstr-link [button]="block.secondaryButton!" [cssClasses]="' button  button--dark'">
            @if (!block.secondaryButton?.text) {
              <span>{{ 'lees-meer' | fromDictionary }}</span>
            }
            <fstr-icon anchor="icon-chevron-right"></fstr-icon>
          </fstr-link>
        }
      </footer>
    }
  </div>
</article>
