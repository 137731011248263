<!-- todo: margin top weghalen -->

@if (cookiePreferences$(); as cookies) {
  <ul class="content-blocks">
    @for (block of blocks; track block.id; let i = $index) {
      <li class="content-blocks__item section-outset-b" [ngClass]="'type--' + block.blockType" [id]="'blok-' + i">
        @switch (block.blockType) {
          @case ('block-principle') {
            <app-content-block-principle [index]="i" [block]="block"></app-content-block-principle>
          }

          @case ('block-team') {
            <app-content-block-team [index]="i" [block]="block"></app-content-block-team>
          }
          @case ('block-image') {
            <app-content-block-image [index]="i" [block]="block"></app-content-block-image>
          }

          @case ('block-news') {
            <app-content-block-news [index]="i" [block]="block"></app-content-block-news>
          }
          @case ('block-default') {
            <app-content-block-default [index]="i" [block]="block"></app-content-block-default>
          }
          @case ('block-partner') {
            <app-content-block-partner [index]="i" [block]="block"></app-content-block-partner>
          }
          @case ('block-nav') {
            <app-content-block-navItem [index]="i" [block]="block"></app-content-block-navItem>
          }
          @case ('block-faq') {
            <app-content-block-faq [index]="i" [block]="block"></app-content-block-faq>
          }
          @case ('block-vacancies') {
            <app-content-block-vacanties [index]="i" [block]="block"></app-content-block-vacanties>
          }
        }
      </li>
    }
  </ul>
}
