import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromContentNews from '../reducers/content-news.reducer';
import { ContentPage } from '../../models';
import * as fromRoot from '../../../store';

export const getContentLatestNewsState = createSelector(fromFeature.getContentState, (state: fromFeature.ContentState) => state.newsPages);

export const getContentLatestNewsEntities = createSelector(getContentLatestNewsState, fromContentNews.getContentLatestNewsEntities);

export const getAllLatestNews = createSelector(getContentLatestNewsEntities, entities => {
  return Object.keys(entities).map(id => entities[id as unknown as number]);
});

// export const getAllLatestNewsOrdered = createSelector(getAllLatestNews, items => {
//   return items.sort((a, b) => (a.publicationDate || 0) - (b.publicationDate || 0));
// });

export const getAllLatestNewsOrdered = createSelector(getAllLatestNews, (values: ContentPage[]) => {
  return values.sort((a, b) => {
    return new Date(b.publicationDate).getTime() - new Date(a.publicationDate).getTime();
  });
});

export const getContentLatestNewsLoaded = createSelector(getContentLatestNewsState, fromContentNews.getContentLatestNewsLoaded);
export const getContentLatestNewsLoading = createSelector(getContentLatestNewsState, fromContentNews.getContentLatestNewsLoading);
