import { Component, ChangeDetectionStrategy, Input, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { getCookiePreferences } from '@teamfoster/sdk/cookie-ngrx';
import { map } from 'rxjs';
import { ContentBlock } from 'src/app/content-blocks/models';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-content-block-container',
  templateUrl: './content-block-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockContainerComponent {
  private store = inject(Store);

  @Input({ required: true }) blocks: ContentBlock[] = [];

  cookiePreferences$ = toSignal(
    this.store.select(getCookiePreferences).pipe(
      map(
        a =>
          a || {
            functional: false,
            analytical: false,
            marketing: false,
          }
      )
    )
  );
}
