import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconModule } from '@teamfoster/sdk/icon';
import { RouterModule } from '@angular/router';
import { ImageModule } from '@teamfoster/sdk/image';
import { Image } from '@teamfoster/sdk/image';
import { Html5PlayerVars, Html5VideoPlayerModule } from '@teamfoster/sdk/html5-video-player';
import { InViewModule } from '@teamfoster/sdk/in-view';
import { CoreModule } from 'src/app/core/core.module';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, IconModule, RouterModule, ImageModule, Html5VideoPlayerModule, InViewModule, CoreModule],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  @Input({ required: true }) title!: string;
  @Input({ required: false }) subtitle!: string;
  @Input({ required: false }) intro!: string;
  @Input({ required: false }) image!: Image;
  @Input({ required: false }) videoBg: string | undefined;
  @Input({ required: false }) playerVars: Html5PlayerVars = {
    autoplay: true,
    controls: false,
    muted: true,
    loop: true,
  };
  @Output() inView = new EventEmitter<any>();
  inviewPort = false;

  constructor() {}

  inViewChange(e: boolean) {
    this.inviewPort = e;
    if (e) {
      this.inView.emit(null);
    }
  }
}
