import { createAction, props } from '@ngrx/store';
import { ContentPage } from '../../models';
import { PagedOverview } from 'src/app/core/models';

export const LoadContentLatestVacancy = createAction('[Vacancy] Load Vacancy of type Vacancy', props<{ take?: number; skip?: number }>());
export const LoadContentLatestVacancySuccess = createAction(
  '[Vacancy] Load Vacancy of type Vacancy Success',
  props<{ content: PagedOverview<ContentPage> }>()
);
export const LoadContentLatestVacancyFail = createAction('[Vacancy] Load Vacancy of type Vacancy Fail', props<{ error: any }>());
