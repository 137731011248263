import { Injectable, Optional, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ContentPage } from '../models';
import { PagedOverview } from 'src/app/core/models';

@Injectable({
  providedIn: 'root',
})
export class ContentPagesService {
  constructor(
    private http: HttpClient,
    @Optional()
    @Inject('BASE_URL')
    private BASE_URL: string
  ) {}

  getPages() {
    return this.http.get<ContentPage[]>(`${this.BASE_URL}api/pages`);
  }

  getPageById(id: number) {
    return this.http.get<ContentPage>(`${this.BASE_URL}api/page/${id}`);
  }

  getContent(skip: number = 0, take: number = 0, contentTypeId: number = 2) {
    return this.http.get<PagedOverview<ContentPage>>(
      `${this.BASE_URL}api/content?take=${take}&skip=${skip}&contentTypeId=${contentTypeId}`
    );
  }
}
